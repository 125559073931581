
@import "node_modules/bootstrap/scss/bootstrap";

fieldset {
    @extend .border;
    @extend .rounded;
    border-color: $gray-400;
}


// file upload
.btn-file {
    position: relative;
    overflow: hidden;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    //min-width: 100%;
    //min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    cursor: inherit;
    display: block;
}
